import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Gallery1 from "../../Assets/Images/g1.jpg";
import Gallery2 from "../../Assets/Images/g2.jpg";
import Gallery3 from "../../Assets/Images/g3.jpg";
import Gallery4 from "../../Assets/Images/g4.jpg";
import Gallery5 from "../../Assets/Images/g5.jpg";
import Gallery6 from "../../Assets/Images/g6.jpg";
import Gallery7 from "../../Assets/Images/g7.jpg";
import Gallery8 from "../../Assets/Images/g8.jpg";
import Gallery10 from "../../Assets/Images/g10.jpg";
import Gallery15 from "../../Assets/Images/g15.jpg";
import Gallery16 from "../../Assets/Images/g17.jpg";
import G1 from "../../Assets/Images/g-1.jpg";
import G3 from "../../Assets/Images/g-3.jpg";
import G4 from "../../Assets/Images/g-4.jpg";
import G5 from "../../Assets/Images/g-5.jpg";
import G6 from "../../Assets/Images/g-6.jpg";
import G7 from "../../Assets/Images/g-7.jpg";
import G8 from "../../Assets/Images/g-8.jpg";
import G9 from "../../Assets/Images/g-9.jpg";
import G10 from "../../Assets/Images/g-10.jpg";
import G11 from "../../Assets/Images/g-11.jpg";
import G12 from "../../Assets/Images/g-12.jpg";
import G13 from "../../Assets/Images/g-13.jpg";
import G14 from "../../Assets/Images/g-14.jpg";




const Gallery = () => {
  const products = [
    { id: 1, image: Gallery1 },
    { id: 2, image: Gallery2 },
    { id: 3, image: Gallery3 },
    { id: 4, image: Gallery4 },
    { id: 5, image: Gallery5 },
    { id: 6, image: Gallery6 },
    { id: 7, image: Gallery7 },
    { id: 8, image: Gallery8 },
    { id: 9, image: Gallery10 },
    { id: 10, image: Gallery15 },
    { id: 11, image: Gallery16 },
    { id: 12, image: G12 },
    { id: 13, image:G13  },
    { id: 14, image: G14 },
    { id : 15, image: G1},
    { id : 16, image: G3},
    { id : 17, image: G4},
    { id : 18, image: G5},
    { id : 19, image: G6},
    { id : 20, image: G7},
    { id : 21, image: G8},
    { id : 22, image: G9},
    { id : 23, image: G10},
    { id : 24, image: G11},
  
  ];

  return (
    <Container>
      <h3 className="text-start mb-5 font-semibold " style={{ color: "#000" }}>
        Gallery
      </h3>
      <Row className="gx-3 gy-1">
        {products.map((product) => (
          <Col key={product.id} xs={12} md={4} className="mb-3">
            <div>
              <img
                src={product.image}
                alt="gallery-img"
                className="img-fluid"
                style={{ width: "100%", height: "300px", objectFit: "cover",borderRadius: "25px" }}
              />
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Gallery;
