import React from "react";
import "./AboutSection.css";
import { Col, Container, Row } from "react-bootstrap";
import RectangleImg3 from "../../Assets/Images/about.svg";
import { Link } from "react-router-dom";

const AboutSection = () => {
  return (
    <div style={{ backgroundColor: "white", paddingTop: "1rem" }}>
      <Container
        className="about-section"
        style={{ backgroundColor: "white" ,paddingTop:"4rem", paddingBottom:"2rem" }}
      >
        <Row>
          <Col xs={12} md={4} className="order-md-1">
            <div className="about-img-container ">
              <img
                alt="plywood-img"
                src={RectangleImg3}
                className="img-fluid"
                style={{ borderRadius: "15px" }}
              />
            </div>
          </Col>
          <Col xs={12} md={8} className="d-flex flex-column order-md-2">
            <p className="about-heading mb-4 font-regular">About Us</p>
            <h3 className="about-title mb-4 font-medium">
              Discover Excellence in Plywood Solutions: Trusted Quality,
              Unmatched Expertise
            </h3>
            <p className="about-desc mb-5 font-regular">
              As one of the leading plywood dealers in South India, Empiric
              Plywood stands at the forefront of the industry, delivering
              exceptional quality and service to our valued customers. With a
              strong reputation built on reliability and expertise, we offer a
              comprehensive range of plywood products tailored to meet the
              diverse needs of builders, architects, and homeowners alike. Our
              commitment to excellence ensures that every product from Empiric
              Plywood meets stringent quality standards, providing durability
              and performance you can trust. Discover why discerning
              professionals choose Empiric Plywood for their plywood needs
              across South India.
            </p>
            <Link to="/about">
              <button className="learn-more-button font-regular">Learn More</button>
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AboutSection;
