import React from "react";
import "./NavigationBar.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Logo from "../../Assets/Images/logo.svg";

function NavigationBar() {
  return (
    <Navbar expand="lg" className="navbar">
      <Container>
        <Navbar.Brand className="text-dark">
          <Link to="/" className="text-decoration-none">
            <img
              className="me-2"
              src={Logo}
              alt="logo"
              style={{ transform: "rotate(1deg)" }}
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="text-dark" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Link to="/" className="text-dark link-nav">
              Home
            </Link>
            <Link to="/about" className="text-dark link-nav">
              About Us
            </Link>
            <Link to="/products" className="text-dark text-capitalize link-nav">
              Products
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
