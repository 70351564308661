import React from "react";
import { Link } from "react-router-dom";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedinIn,
  FaWhatsapp,
} from "react-icons/fa";
import { Container, Col, Row } from "react-bootstrap";
import X from "../../Assets/Images/x.png";

const FooterSection = () => {
  return (
    <>
      {/*============== Get in touch ============== */}
      <br />
      <Container>
        <Row className="p-4 mx-2 my-5 flex align-items-center border border-gray rounded ">
          <Col md={6} className="p-2 my-4">
            <h4 className="font-medium">Get in touch with us</h4>
          </Col>

          <Col md={6}>
            <Row>
              <Col md={6}>
                <div>
                  <p className="font-regular">Email</p>
                  <h6 className="text-success mb-4 font-medium">
                    <a
                      href="mailto:empiricply@gmail.com"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      empiricply@gmail.com{" "}
                    </a>
                  </h6>
                </div>
              </Col>

              <Col md={6}>
                <div>
                  <p className="font-regular">Phone Number</p>
                  <h6 className="text-success font-medium">
                    <a
                      href="tel:+918075767971"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      +91 80757 67971
                    </a>
                    <br></br>
                    <a
                      href="tel:+919633300961"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      +91 96333 00961
                    </a>
                  </h6>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <br />

      {/* =============== Footer section ================ */}
      <div style={{ width: "100%" }} className="bg-dark footer-section-fixed">
        <Container className="d-flex justify-content-center py-4">
          <Row className="w-100" style={{ maxWidth: "100%" }}>
            <Col
              xs={12}
              md={4}
              className="mb-4 mb-md-0 d-flex flex-column align-items-center align-items-md-start text-center text-md-start"
            >
              <div>
                <h5 className="text-light font-medium">Address</h5>
                <p className="text-light font-regular">
                  Chelamattom,
                  <br />
                  Perumbavoor, Kerala
                  <br />
                  info@empiricplywood.com
                </p>
              </div>
            </Col>

            <Col
              xs={12}
              md={4}
              className="mb-4 mb-md-0 d-flex flex-column align-items-center text-center text-md-center"
            >
              <div>
                <h5 className="text-light font-medium">Quick Links</h5>
                <p className="font-regular">
                  <Link
                    to="/"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Home
                  </Link>
                </p>
                <p className="font-regular">
                  <Link
                    to="/about"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    About Us
                  </Link>
                </p>
                <p className="font-regular">
                  <Link
                    to="/products"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Products
                  </Link>
                </p>
              </div>
            </Col>

            <Col
              xs={12}
              md={4}
              className="mb-4 mb-md-0 d-flex flex-column align-items-center align-items-md-end text-center text-md-end"
            >
              <div>
                <h5 className="text-light font-medium">Follow Us</h5>
                <a
                    href="https://wa.me/918075767971"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="me-3 text-light"
                  >
                    <FaWhatsapp size="1.5rem" />
                  </a>
                <a
                  href="https://www.linkedin.com/company/empiric-syndicates/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="me-3 text-light"
                >
                  <FaLinkedinIn  size="1.5rem" />
                </a>
                <a
                  href="https://www.instagram.com/empiric_plywood?igsh=MTB6ajVpcGszNndyeQ%3D%3D"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-light me-3"
                >
                  <FaInstagram  size="1.5rem"/>
                </a>
                <a
                  href="https://www.facebook.com/people/Empiric-Plywood/pfbid0zFGerwzLqpTQCdmhMJzk48MdpC4TFqYGUjXqXeVA77You1CSSh2rKcdKthtDfyEml/?mibextid=LQQJ4d"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-light me-3"
                >
                  <FaFacebook size="1.5rem"/>
                </a>
                <a
                  href="https://x.com/empiricply?s=21"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-light"
                >
                  <img
                    src={X}
                    alt="twitter"
                    style={{ width: "23px", height: "20px" }}
                  />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default FooterSection;
