import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Empiric.css";
import Icon1 from "../../Assets/Icons/icon-1.svg";
import Icon2 from "../../Assets/Icons/icon-2.svg";
import Icon3 from "../../Assets/Icons/icon-3.svg";
import Icon4 from "../../Assets/Icons/icon-4.svg";
import Icon5 from "../../Assets/Icons/icon-5.svg";
import Icon6 from "../../Assets/Icons/icon-6.svg";

const Empiric = () => {
  const items = [
    {
      id: 1,
      icon: Icon1,
      title: "High Quality Products",
      description:
        "We offer a wide selection of high-grade plywood products, sourced from trusted manufacturers and crafted to meet stringent quality standards.",
    },
    {
      id: 2,
      icon: Icon2,
      title: "Expert Knowledge and Experience",
      description:
        "We offer a wide selection of high-grade plywood products, sourced from trusted manufacturers and crafted to meet stringent quality standards.",
    },
    {
      id: 3,
      icon: Icon3,
      title: "Competitive Pricing",
      description:
        "We believe in providing exceptional value for money. Our competitive pricing ensures you get the best quality plywood without breaking the bank.",
    },
    {
      id: 4,
      icon: Icon4,
      title: "Reliable Customer Service",
      description:
        "Our dedicated customer service team is always ready to assist you, providing timely and helpful support through every step of your purchase and beyond.",
    },
    {
      id: 5,
      icon: Icon5,
      title: "Fast and Efficient Delivery",
      description:
        "We understand the importance of timely delivery. Our efficient logistics network ensures your orders are delivered promptly and in perfect condition.",
    },
    {
      id: 6,
      icon: Icon6,
      title: "Positive Customer Feedback",
      description:
        "Our reputation is built on the trust and satisfaction of our customers. Read our reviews to see why so many choose us for their plywood needs.",
    },
  ];

  return (
    <div  style={{backgroundColor: 'white'}}>

    <Container className="empiric-container">
      <h3 className="text-start mb-5 fw-semibold">Why Empiric?</h3>
      <Row>
        {items.map((item) => (
          <Col
            key={item.id}
            sm={12}
            md={6}
            lg={6}
            className="mb-4 d-flex align-items-stretch"
          >
            <div className="empiric-item text-start p-3 ">
              <img src={item.icon} alt={item.title} className="icon mb-3"/>
              <h4 className="fw-semibold">{item.title}</h4>
              <p>{item.description}</p>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
    </div>
  );
};

export default Empiric;
