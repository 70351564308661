import React, { useEffect } from "react";
import $ from "jquery";
import HeroSection from "../../Components/HeroSection/HeroSection";
import AboutSection from "../../Components/AboutSection/AboutSection";
import ServicesSection from "../../Components/Services/ServicesSection";
import Empiric from "../../Components/EmpiricSection/Empiric";
import "./Home.css";

function Home() { 

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = $(window).scrollTop();
      const heroHeight = $('.hero-container').height();
      const startHidingPoint = heroHeight + 20; 

      if (scrollTop <= startHidingPoint) {
        $(".hero-container").css({
          transform: `translateY(0)`,
          opacity: 1,
        });
      } else {
        $(".hero-container").css({
          transform: 'translateY(-100%)',
          opacity: 0,
        });
      }
    };

    $(window).on('scroll', handleScroll);

    return () => {
      $(window).off('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="home-page">
      <div className="hero-container">
        <HeroSection />
      </div>
      <div className="scroll-content">
        <AboutSection />
        <ServicesSection />
        <Empiric />
      </div>
    </div>
  );
}

export default Home;
