import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Shuttering from "../../Assets/Images/Shuttering.jpg";
import Particle from "../../Assets/Images/Particle.svg";
import MDF from "../../Assets/Images/MDF.svg";
import PVC from "../../Assets/Images/PVC.svg";
import Calibrate from "../../Assets/Images/Calibrate.svg";
import Checked from "../../Assets/Images/Checked .jpg";
import Commercial from "../../Assets/Images/Commercial.jpg";

const ProductDetails = () => {
  const products = [
    {
      id: 1,
      name: "Shuttering Plywood",
      image: Shuttering,
      desc: "Shuttering plywood, or formwork plywood, is used in construction to make molds for concrete. It withstands wet concrete pressure, retains shape, and is durable, water-resistant, and smooth for easy removal.",
    },
    {
      id: 2,
      name: "Particle board",
      image: Particle,
      desc: "Particle board, made from wood chips and resin, is used for furniture, cabinetry, and flooring underlayment. It is cheaper but less strong and durable than solid wood and plywood.",
    },
    {
      id: 3,
      name: "MDF board",
      image: MDF,
      desc: "MDF is a cost-effective alternative to wood, with a uniform structure and smooth surface. Available in 2mm, 3mm, 4mm, and 5.5mm, it is termite-proof, stronger, and denser than particle board, ideal for budget-friendly interiors.",
    },
    {
      id: 4,
      name: "PVC Board",
      image: PVC,
      desc: "PVC boards are durable, water-resistant, lightweight, termite-resistant, fire-retardant, and easy to clean.They are used in construction, furniture, and signage, and can be cut, shaped, and painted. Cost-effective and low maintenance.",
    },
    {
      id: 5,
      name: "Calibrate Plywood",
      image: Calibrate,
      desc: "Calibrated plywood is finely sanded and machined for uniform thickness, ensuring consistency and precision. Ideal for furniture, cabinetry, and interior design, it is perfect for applications needing exact dimensions and smooth surfaces.",
    },
    {
      id: 6,
      name: "Checked board",
      image: Checked,
      desc: "Checked plywood, or anti-slip plywood, has a textured surface for extra grip and is used for industrial flooring, vehicle decks, and scaffolding to enhance safety.",
    },
    {
      id: 7,
      name: "Commercial Plywood",
      image: Commercial,
      desc: "Semi-hardwood plywood, with a hardwood core and veneers, is strong, durable, and available in 6 mm to 25 mm sizes. It has a more complex structure than softwoods.",
    },
  ];

  return (
    <Container>
      <h3 className="text-start mb-5 font-semibold " style={{ color: '#000' }} > Our Products</h3>
      <Row className="gx-3 gy-1">
        {products.map((product) => (
          <Col key={product.id} xs={12} md={4} className="mb-3 ">
            <div>
              <img src={product.image} alt={product.name} className="img-fluid" style={{ width: '100%', height: '300px', objectFit: 'cover',borderRadius:'25px', }} />
            </div>
            <h5 className="font-medium mt-4">{product.name}</h5>
            <p className="font-regular">{product.desc}</p>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default ProductDetails;
