import React from 'react'
import ProductDetails from '../../Components/ProductDetails/ProductDetails'

const Products = () => {
  return (
    <div className='products-page ' style={{marginTop: '30px'}}>
        <ProductDetails/>
    </div>
  )
}

export default Products