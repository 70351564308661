import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Mission.css';
import MissionIcon from '../../Assets/Images/target.svg';  
import VisionIcon from '../../Assets/Images/visionlogo.svg';  

const Mission = () => {
  const items = [
    { id: 1, icon: VisionIcon, title: 'Our Vision', description: 'To be the leading provider of premium ,sustainable plywood, offering innovative products, exceptional customer service, nad fostering strong community realationships for a greener and more prosperous future.' },
    { id: 2, icon: MissionIcon, title: 'Our Mission', description: 'Our mission is to supply the finest plywood products that  meet the diverse needs of our clients. We belive in building long term relationships based on trust, reliability, and excellence. We source oru materials from sustainable and responsilbe suppliers, ensuring that our products not only meet but exceed industry standards.' },
  ]

  return (
    <Container className="mission-container">
      <Row>
        {items.map((item) => (
          <Col key={item.id} sm={12} md={6} lg={6} className="mb-4 d-flex align-items-stretch">
            <div className="mission-item text-start p-3 ">
              <img src={item.icon} alt={item.title} className="icon mb-3"  height={25} style={{ width: '50px', height: '50px', objectFit: 'contain'}}/>
              <h4 className="font-medium">{item.title}</h4>
              <p className='font-regular'>{item.description}</p>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Mission;
