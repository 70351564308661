import './App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './Pages/Home/Home';
import NavigationBar from './Components/NavigationBar/NavigationBar';
import FooterSection from './Components/Footer/FooterSection';
import About from './Pages/About/About';
import Products from './Pages/Products/Products';

function App() {
  return (
    <div className="App">
      <NavigationBar />

      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/products' element={<Products />} /> 
      </Routes>

      <FooterSection />
    </div>
  );
}

export default App;
