import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import RectangleImg2 from "../../Assets/Images/About2.svg";

const AboutDesc = () => {
  return (
    <Container className="about-section">
      <Row>
        <Col xs={12} md={6} className="text-start">
          <p className="font-regular">About Us</p>
          <h3 className="mb-4 font-medium about-title">
            Trusted provider of durable, high-quality plywood for all
            construction needs.
          </h3>
          <p className="about-desc font-regular" >
            At Empiric Plywood, we are passionately committed to delivering
            durable, high-quality plywood solutions that cater to all your
            construction and design needs, with a steadfast dedication to
            excellence and customer satisfaction that ensures every product we
            provide meets the highest standards of craftsmanship and
            reliability, thereby solidifying our reputation as your trusted
            partner in building strong, lasting foundations for any project,
            large or small, and fostering enduring relationships based on trust,
            quality, and unparalleled service. choose Empiric Plywood for their
            plywood needs across South India.
          </p>
        </Col>
        <Col xs={12} md={6} className="d-flex justify-content-center align-items-center">
          <img alt="plywood-img" src={RectangleImg2} className="img-fluid" style={{borderRadius: '15px'}} />
        </Col>
      </Row>
    </Container>
  );
};

export default AboutDesc;
