import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./CountSection.css";

const CountSection = () => {
  return (
    <Container className="my-5">
      <Row className="text-center justify-content-between">
        <Col xs={12} sm={4} className="mb-4">
          <div className="d-flex justify-content-start align-items-center">
            <h2 className="me-2 font-medium">10K+</h2>
            <p className="mb-0 font-regular">Happy Customers</p>
          </div>
          <hr className="custom-hr" />
        </Col>
        <Col xs={12} sm={4} className="mb-4">
          <div className="d-flex justify-content-center align-items-center">
            <h2 className="me-2 font-medium">10+</h2>
            <p className="mb-0 font-regular">Years of Experience</p>
          </div>
          <hr className="custom-hr" />
        </Col>
        <Col xs={12} sm={4} className="mb-4">
          <div className="d-flex justify-content-end align-items-center">
            <h2 className="me-2 font-medium">15+</h2>
            <p className="mb-0 font-regular">Projects Completed</p>
          </div>
          <hr className="custom-hr" />
        </Col>
      </Row>
    </Container>
  );
};

export default CountSection;
