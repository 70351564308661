import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./ServicesSection.css";
import Check from "../../Assets/Icons/check.svg";

const ServicesSection = () => {
  const services = [
    {
      id: 1,
      title: "Custom Cutting",
      description:
        "Need plywood cut to specific dimensions? Our custom cutting service provides precise cuts tailored to your exact requirements.",
    },
    {
      id: 2,
      title: "Expert Advice",
      description:
        "Get professional advice from our experienced team. Whether you need help choosing the right product or tips on installation, we’re here to help.",
    },
    {
      id: 3,
      title: "Installation Guides and Resources",
      description:
        "Access a comprehensive library of installation guides, and maintenance tips to ensure your plywood is installed correctly and lasts for years.",
    },
  ];

  return (
    <div style={{ backgroundColor: "white",paddingTop: "2rem",paddingBottom: "2rem" }} >
      <Container className="services-container">
        <h3 className="text-start mb-4 font-semibold">Our Services</h3>
        <Row>
          {services.map((service) => (
            <Col key={service.id} md={6} className="service-col">
              <div className="service-item">
                <div className="service-icon">
                  <img src={Check} alt="Check icon" width={20} height={20} />
                </div>
                <div className="service-content">
                  <h6 className="text-start mb-3 font-medium">{service.title}</h6>
                  <p className="text-start font-regular">{service.description}</p>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default ServicesSection;
