import React from "react";
import Slider from "react-slick";
import { Row } from "react-bootstrap";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "./HeroSection.css";
import web1 from "../../Assets/Images/web-1.svg";
import web2 from "../../Assets/Images/web-2.svg";
import web3 from "../../Assets/Images/web-3.svg";
import textLogo from "../../Assets/Images/empiric-logo.svg";

const HeroSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="hero-section">
      <Row className="logo-container justify-content-center " >
        <img src={textLogo} alt="text-logo" className="title-img" />
      </Row>
      <Row className="web-img-row">
        <Slider {...settings}>
          <div>
            <img src={web1} alt="web-1" className="web-img" />
          </div>
          <div>
            <img src={web2} alt="web-2" className="web-img" />
          </div>
          <div>
            <img src={web3} alt="web-3" className="web-img" />
          </div>
        </Slider>
      </Row>
    </div>
  );
};

export default HeroSection;
