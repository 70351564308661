import React from 'react'
import Gallery from '../../Components/Gallery/Gallery'
import Mission from '../../Components/Mission/Mission'
import CountSection from '../../Components/CountsSection/CountSection'
import AboutDesc from '../../Components/AboutDesc/AboutDesc'
import Directors from '../../Components/Directors/Directors'

const About = () => {
  return (
    <div className='about-page' style={{marginTop: '30px'}}>
    <AboutDesc/>
    <CountSection/>
    <Mission/>
    <Directors/>
    <Gallery/>
</div>
  )
}

export default About